

import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import activeDataReducer from './reducers/activeDataReducer';
import authReducer from "./reducers/authReducer";
import clientOptionReducer from './reducers/clientOptionReducer';
import messageReducer from './reducers/messageReducer';


const rootReducer = combineReducers({
  auth: authReducer,
  clientOptions: clientOptionReducer,
  messageData: messageReducer,
  activeData: activeDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, applyMiddleware(thunk));