import { ArrowCounterclockwise24Regular, CalendarDate24Regular, Chat24Regular, Clock24Regular, DocumentSearch24Regular, DocumentText24Regular, Folder24Regular, Person24Filled, Search24Regular, ZoomIn24Regular } from "@fluentui/react-icons";
import { Department } from "../../models/Department";
import IconToggleButton from "../IconToggleButton";

import { ViewSettings } from "../../models/ViewSettings";


interface IGeneralTableProps {
    settings: ViewSettings;
    updateSettings: React.Dispatch<React.SetStateAction<ViewSettings>>;
    departments: Department[];
    selectedDepartment: Department | undefined;
    onDepartmentChange: (department: Department | undefined) => void;
}



const General = (props: IGeneralTableProps) => {

    const now = new Date();

    return (
        <div className='topmenue'>
            <div className='smallSection'>
                <div>
                    <div className='horizontalRow m-1'>
                        <IconToggleButton
                            Icon={Search24Regular}
                            text="Nollställ sök (CTRL + D)"
                            onClick={() => {}}
                            isActive={false}
                        />
                        <IconToggleButton
                            Icon={Person24Filled}
                            text="Skapade av mig"
                            onClick={() => {}}
                            isActive={false}
                        />
                    </div>
                    <div style={{ flex: 1, flexDirection: "row" }}>
                        <input
                            className='searchInput'
                            name='OFF_Id'
                            placeholder='sök'
                            type='text'
                            onChange={() => {}}
                        />
                        <button style={{ marginLeft: 10, backgroundColor: "white" }}>
                            sök
                        </button>
                    </div>
                </div>
                
                <div className='sectionFooter'>
                    Sök
                </div>
            </div>
            <div className='largeSection'>
                <div className='horizontalRow'>
                    <div>
                        <div className='horizontalRow'>
                            <div className='input-group-prepend'>
                                <label
                                    style={{ marginLeft: 5, fontSize: 15 }}
                                    htmlFor='inputGroupSelect01'
                                >
                                    {"Inställning: "} 
                                </label>
                            </div>
                            <select
                                className='dropdown'
                                id='departmentView'
                                name='departmentView'
                                onChange={() => {}}
                                style={{ marginLeft: 5 }}
                            >
                                <option id="standard" value="standard">standard</option>
                                {/* {props.departments.map((department) => (
                                    <option key={department.id} value={department.id}>
                                        {department.departmentName}
                                    </option>
                                ))} */}
                            </select>
                        </div>
                        <div className='horizontalRow'>
                            <div className='input-group-prepend'>
                                <label
                                    style={{ marginLeft: 5, fontSize: 15 }}
                                    htmlFor='inputGroupSelect01'
                                >
                                    {"Filter: "}
                                </label>
                            </div>
                            <select
                                className='dropdown'
                                id='filterView'
                                name='filterView'
                                onChange={(item) => {
                                    if (item.target.value === "--") {
                                        props.onDepartmentChange(undefined);
                                        return;
                                    }

                                    const dep = props.departments.find((department) => department.id == item.target.value);

                                    if (dep) {
                                        props.onDepartmentChange(dep);
                                    }
                                }}
                                style={{ marginLeft: 5 }}
                            >
                                <option id="--" value="--">--</option>
                                {props.departments.map((department) => (
                                    <option key={department.id} value={department.id} selected={department == props.selectedDepartment}>
                                        {department.departmentName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='inactiveActionButton' style={{ display: "flex", flexDirection: "row" }}>
                            <CalendarDate24Regular />
                            <div style={{ marginLeft: 4 }}>
                                <input
                                    type='datetime-local'
                                    className='form-control'
                                    id='dateviewStart'
                                    name='dateviewStart'
                                    defaultValue={now.toISOString().slice(0, 16)}
                                />
                            </div>
                        </div>
                        <IconToggleButton
                            Icon={ZoomIn24Regular}
                            text="Förplanering (CTR + Q)"
                            onClick={() => {}}
                            isActive={false}
                        />
                    </div>
                    <div className='column'>
                        <IconToggleButton
                            Icon={Chat24Regular}
                            text="skicka meddelanden"
                            onClick={() => {}}
                            isActive={true}
                        />
                        <IconToggleButton
                            Icon={Clock24Regular}
                            text="dölj inaktiva resurser"
                            onClick={() => props.updateSettings({ ...props.settings, hideIactive: !props.settings.hideIactive })}
                            isActive={props.settings.hideIactive}
                        />
                        <IconToggleButton
                            Icon={DocumentSearch24Regular}
                            text="multisökning"
                            onClick={() => props.updateSettings({ ...props.settings, mulitSearch: !props.settings.mulitSearch })}
                            isActive={props.settings.mulitSearch}
                        />
                    </div>
                    <div className='column'>
                        <IconToggleButton
                            Icon={ZoomIn24Regular}
                            text="visa kolumn filter"
                            onClick={() => props.updateSettings({ ...props.settings, showColumnFilter: !props.settings.showColumnFilter })}
                            isActive={props.settings.showColumnFilter}
                        />
                        <IconToggleButton
                            Icon={DocumentText24Regular}
                            text="rensa kolumn filter (CTR + SHIFT + D)"
                            onClick={() => props.updateSettings({ ...props.settings, columnFilter: [] })}
                            isActive={false}
                        />
                        <IconToggleButton
                            Icon={ZoomIn24Regular}
                            text="markera ändrade updrag"
                            onClick={() => {}}
                            isActive={true}
                        />
                    </div>
                    <div className='column'>
                        <IconToggleButton
                            Icon={ArrowCounterclockwise24Regular}
                            text="sortera om löpande uppdrag"
                            onClick={() => props.updateSettings({ ...props.settings, sortLiveMissions: !props.settings.sortLiveMissions })}
                            isActive={props.settings.sortLiveMissions}
                        />
                        <IconToggleButton
                            Icon={Folder24Regular}
                            text="öppna order (CTR + O)"
                            onClick={() => {}}
                            isActive={false}
                        />
                    </div>
                </div>
                <div className='sectionFooter'>
                    Inställningar
                </div>
            </div>
        </div>
    );

}

export default General;