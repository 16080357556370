// App.tsx
import { useToastController } from "@fluentui/react-toast";
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { bindActionCreators } from "redux";
import Snackbar from './components/Snackbar';
import { eventEmitter, Events, GlobalToaster } from './helpers/eventEmitter';
import StorageKeys from "./helpers/StorageKeys";
import { AuthResult } from "./models/AuthResult";
import { LogInPage } from './pages/LoginPage';
import GeneralPage from './pages/MainPage';
import ProtectedRoute from "./routeGuard";
import { tokenLifetime } from "./Services/authService";
import { actionCreators } from "./state";
import { store } from "./state/store";


export const globalToasterId = "global-toaster";

const App = () => {
  const dispatchToast = useToastController(globalToasterId).dispatchToast;

  useEffect(() => {
    eventEmitter.subscribe(
      Events.SHOW_GLOBAL_TOASTER,
      (data: GlobalToaster) => {
        dispatchToast(
          <Snackbar
            title={data.title}
            subtitle={data.subtitle}
            footer={data.footer}
          />,
          { ...data.options }
        );
      }
    );
    return () => {
      eventEmitter.unsubscribe(Events.SHOW_GLOBAL_TOASTER);
    };
  }, []);

  const auth = JSON.parse(sessionStorage.getItem(StorageKeys.user) || "{}") as AuthResult | undefined;

  const tokenTime = tokenLifetime(auth?.refreshToken);

  console.log("tokenTime", tokenTime);

  if (tokenTime > 60) {

    const addAuth = bindActionCreators(actionCreators, store.dispatch).addAuth;

    addAuth({ auth: auth! });
  }

  return (
    <BrowserRouter basename={""}>
      <Routes>
        <Route path="/" element={<Navigate to={tokenTime > 60 ? "/Main":  "/Login"} replace />} />
        <Route path="/Login" element={<LogInPage />} />
        <Route
          path="/Main"
          element={
            <ProtectedRoute>
              <GeneralPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
