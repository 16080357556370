import { pods } from "../helpers/TranslationMaps";
import { CurrentOrderData } from "../models/CurrentOrderData";



interface IstatusMenueProps {
    activeOrders: CurrentOrderData | undefined;
}


const StatusMenue = (props: IstatusMenueProps) => {

    let internetOrderCount = 0;
    let messageCount = 0;
    let deniedCount = 0;
    let pendingCount = 0;
    let linkMessageCount = 0;

    const orderCount = (props.activeOrders?.unDistributed?.length || 0)

    for (let i = 0; i < orderCount; i++) {
        if (props.activeOrders!.unDistributed![i].pod === pods.denied) {
            deniedCount++;
        }
    }


    return (
        <div className='messageSection'>
            <div className='messageStat'>
                <div>internet ordrar</div>
                <div>{internetOrderCount}</div>
            </div>
            <div className='messageStat'>
                <div>förarmeddelanden</div>
                <div>{messageCount}</div>
            </div>
            <div className='messageStat'>
                <div>nekade uppdrag</div>
                <div>{deniedCount}</div>
            </div>
            <div className='messageStat'>
                <div>väntande förändringar</div>
                <div>0</div>
            </div>
            <div className='messageStat'>
                <div>link meddelanden</div>
                <div>{pendingCount}</div>
            </div>
            <div className='messageStat'>
                <div>pending</div>
                <div>{linkMessageCount}</div>
            </div>
        </div>
    );
}


export default StatusMenue;