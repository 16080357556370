

import { Radio, RadioGroup } from '@fluentui/react-components';
import { useState } from 'react';
import { cap, formatASCII } from '../Services/format';


interface IMissionInfoProps {
    mission: any | undefined;
}

const MissionInfo = (props: IMissionInfoProps): JSX.Element => {
    const [selectedRadio, setSelectedRadio] = useState<string>("0");
    const [selectedRadioMessage, setSelectedRadioMessage] = useState<string>("0");

    if (props.mission === undefined) {
        return <div style={{ minHeight: 100 }}></div>;
    }

    const compDate = props.mission.created.split(" ")[0]?.slice(2).replaceAll("-", "");

    const loadStartDate = props.mission.fromTimePickup.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);
    const loadEndDate = props.mission.toTimePickup.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);

    const deliverStartDate = props.mission.recFromTimeDeliver.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);
    const deliverEndDate = props.mission.recLastLevTime.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);

    return (
        <div className="horizontalRow" style={{ padding: "0px", gap: "20px" }}>
            <div className="column" style={{ gap: "0px" }}>
                <div style={{ fontSize: 20, marginBottom: 4 }}>Allmänt</div>
                <div>Skellefteå lastbilsstation</div>
                <div>{props.mission.orderNr}</div>
                <div>{"?"}</div>
                <div>System.</div>
                <div>{props.mission.created?.replace("T", " ").replace(/\-00$/, "")}</div>
            </div>
            <div className="column" style={{ gap: "0px", minWidth: 180 }}>
                <div style={{ fontSize: 20, marginBottom: 4 }}>Tider</div>
                <div>{"Lastas tidigast:  " + compDate + " " + (loadStartDate || "")}</div>
                <div>{"Lastas senast:    " + compDate + " " + (loadEndDate || "")}</div>
                <div>{"Lossas tidigast:  " + compDate + " " + (deliverStartDate || "")}</div>
                <div>{"Lossas senast:    " + compDate + " " + (deliverEndDate || "")}</div>
            </div>
            <div className="column" style={{ gap: "0px" }}>
                <div className="horizontalRow" style={{ padding: "0px", minWidth: 380 }}>
                    <div style={{ fontSize: 20, marginBottom: 4 }}>Adress</div>
                    <RadioGroup style={{ flexDirection: "row" }}>
                        <Radio value="0" label="Uppdrag" checked={selectedRadio == "0"} onChange={(value) => setSelectedRadio(value.target.value)} />
                        <Radio value="1" label="Order" checked={selectedRadio == "1"} onChange={(value) => setSelectedRadio(value.target.value)} />
                    </RadioGroup>
                </div>
                <RadioInfoSection index={Number(selectedRadio)} info={props.mission} />
            </div>
            <div className="column" style={{ gap: "0px" }}>
                <div style={{ fontSize: 20, marginBottom: 4 }}>Dimensioner</div>
                <div>{"Vikt:   " + parseFloat(props.mission.ordervikt || "0.0") + " kg"}</div>
                <div>{"Kollin: " + (props.mission.kolliantal || "") + " st"}</div>
            </div>
            <div className="column" style={{ gap: "0px" }}>
                <div style={{ fontSize: 20, marginBottom: 4 }}>Meddelanden</div>
                <RadioGroup style={{ gap: "0px" }}>
                    <Radio style={{ marginTop: -6, marginBottom: -6, marginLeft: -3 }} value="0" label="Sammanställning"       checked={selectedRadioMessage == "0"} onChange={(value) => setSelectedRadioMessage(value.target.value)} />
                    <Radio style={{ marginTop: -6, marginBottom: -6, marginLeft: -3 }} value="1" label="Internt"               checked={selectedRadioMessage == "1"} onChange={(value) => setSelectedRadioMessage(value.target.value)} />
                    <Radio style={{ marginTop: -6, marginBottom: -6, marginLeft: -3 }} value="2" label="Externt meddelande"    checked={selectedRadioMessage == "2"} onChange={(value) => setSelectedRadioMessage(value.target.value)} />
                    <Radio style={{ marginTop: -6, marginBottom: -6, marginLeft: -3 }} value="3" label="leveransinstruktioner" checked={selectedRadioMessage == "3"} onChange={(value) => setSelectedRadioMessage(value.target.value)} />
                    <Radio style={{ marginTop: -6, marginBottom: -6, marginLeft: -3 }} value="4" label="Extra"                 checked={selectedRadioMessage == "4"} onChange={(value) => setSelectedRadioMessage(value.target.value)} />
                </RadioGroup>
            </div>
            <input style={{ height: "100%", width: "100%" }}></input>
        </div>
    );
}

export default MissionInfo;


interface IRadioInfoSectionProps {
    index: number;
    info: any | undefined;
}

const RadioInfoSection = (props: IRadioInfoSectionProps): JSX.Element => {

    switch (props.index) {
        case 0: 
        return (
            <div className="horizontalRow" style={{ padding: "0px" }}>
                <div>
                    <div>{(props.info.custNr || "-") + " " + (props.info.avsCompany?.replace("/", "+").replace(/([a-zåäö0-9]+)\s?(ab)(.*)/i, "$1 $2 $3") || "-")}</div>
                    <div>{cap(props.info.avsAdrHouseNr) || "-"}</div>
                    <div>{(props.info.avsPostnr || "-") + " " + (formatASCII(props.info.avsPostOrt) || "-")}</div>
                    <div>{(props.info.avsPhone || "-")}</div>
                </div>
                <div>
                <div>{props.info.recCompany?.replace("/", "+").replace(/([a-zåäö0-9]+)\s?(ab)(.*)/i, "$1 $2 $3") || "-"}</div>
                    <div>{cap(props.info.recadressHouseNr) || "-"}</div>
                    <div>{(props.info.recPostnr || "-") + " " + (formatASCII(props.info.avsPostOrt) || "-")}</div>
                    <div>{(props.info.recPhone || "-")}</div>
                </div>
            </div>
        )
        case 1:
        return (
            <div className="column">
                <div>{props.info.task}</div>
                <div>{props.info.taskType}</div>
                <div>{props.info.taskDescription}</div>
            </div>
        )
    }

    return <div></div>;
}