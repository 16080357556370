import { DateTime } from 'luxon';
import moment from 'moment';
import { Conversation } from '../models/messages';

export const clamp = (number: number, min: number, max: number): number => number > max ? max: (number < min ? min: number);

export const OpterDateNow = (): string => DateTime.now().toString().split(".")[0].replace("T", " ");

export const OpterDateFormat = (Date: DateTime<true>): string => Date.toString().split(".")[0].replace("T", " ");

export const precisionDateNow = (): string => moment.utc().toISOString();

export const stripNumber = (text?: string): string => parseFloat(text || "0.0").toString();
 
export const formatPhone = (recPhone: string | undefined): string => recPhone ? ((recPhone.startsWith("46") && recPhone.length > 3 ? "+": "") + recPhone): "-";

export const formatVersion = (version: string): string => version.replaceAll(/\.0(\d+)/g, '.$1');
 

export const formatASCII = (city: string | undefined): string | undefined => {
    if (city === undefined) return city;
  
    return city.toLowerCase().replaceAll("aa", "å").replaceAll("ae", "ä").replaceAll("oe", "ö").replace(/^\w/, (c: string) => c.toUpperCase()).replace(" ab", " AB");
}

export const formatCity = (city: string): string => {
   let formatted = formatASCII(city)!.trim().replaceAll(/\d+/g, "");

   formatted = formatted.replaceAll("soder", "söder").replaceAll("oster", "öster").replaceAll("vast", "väst").replace(/^\w/, c => c.toUpperCase());

    return formatted;
}

export const cap = (recAddressHouseNr: string | undefined): string | undefined => {
    if (!recAddressHouseNr) return recAddressHouseNr;

    return recAddressHouseNr.toLowerCase().replace(/^\w/, c => c.toUpperCase());
}

export const formatAddress = (recAddressHouseNr: string): string => {
    let formatted: string = recAddressHouseNr.toLowerCase().trim();

    if (/^\d+\s*[a-ö]/.test(formatted)) {
        formatted = formatted.replace(/^(\d+)\s+(.*?)(\s+\d+)?$/, (_, p1, p2) => `${p2} ${p1}`).trim();
    }

    if (/([a-ö])\s*(\d+)*/.test(formatted)) {
        formatted = formatted.replace(/^(\d+[a-ö])\s*(.*)$/, (_, p1, p2) => `${p2} ${p1}`).trim();
    }

    formatted = removeDuplicates(formatted)!;

    formatted = formatted.replaceAll("vag", "väg").replaceAll("sjo", "sjö").replaceAll("gard", "gård").replace(/^\w/, c => c.toUpperCase());

    return formatted;
}


export const removeDuplicates = (string: string | undefined): string | undefined => {
    if(!string) return string;

    const words = string.split(' ');

    const seen: Set<string> = new Set();

    const uniqueWords = words.filter(word => {
        const alreadySeen = seen.has(word);
        seen.add(word);
        return !alreadySeen;
    });

    string = uniqueWords.join(' ');

    return string;
}

export const sortConversations = (conversations: Conversation[] | undefined): Conversation[] => {
    console.log("Sorting", (conversations || []).length, "conversations");
    try {

        const dataCopy: Conversation[] = [...(conversations || [])];

        dataCopy.sort((a: Conversation, b: Conversation) => {
            const stamp_a = a.messages.length > 0 ? a.messages[a.messages.length - 1].timeStamp: "2000-01-01 00:00:00";
            const stamp_b = b.messages.length > 0 ? b.messages[b.messages.length - 1].timeStamp: "2000-01-01 00:00:00";

            return moment(stamp_a).diff(moment(stamp_b), "milliseconds") * -1;
        });

        return dataCopy;
    } catch (e) {
        console.log(e);
    }

    return (conversations || []);
}

export const formatTimestamp = (timestamp: string) => {
    const messageDate = moment(timestamp);
    const now = moment();

    const dayNumberDiffrent = now.dayOfYear() - messageDate.dayOfYear();
    const sameMinute = now.diff(messageDate, "seconds") < 60;
    const day24hDiff = now.diff(messageDate, 'days');
  
    if(sameMinute) {
        return "Now";
    } else if (dayNumberDiffrent === 0 && day24hDiff === 0) {
      return messageDate.format('HH:mm');
    } else if (day24hDiff < 7) {
        return messageDate.format('ddd HH:mm');
    } else {
      return messageDate.format('MM/DD HH:mm');
    }
};

export const fomratexid5 = (exid5: string | undefined): string => {
    try {
        const id5 = exid5?.toLocaleLowerCase().trim();

        if (id5 && /^(tidspass|hämttid)\s*(\d+)/.test(id5)) {
            const split = /(tidspass|hämttid)\s*(\d+)/.exec(id5);

            if(!split || split.length < 3) return id5;
    
            const time = split[2].match(/\d{1,2}/g);
    
            if(!time || time.length < 2) return id5;
    
            let fomatted = split[1] + " ";
            for(let i = 0; i < time.length; i++) {
            fomatted += (i !== 0 ? ((time.length === 4 && i === 2 || time.length === 6 && i === 3) ? " - ": ":"): "") + time[i];
            }
    
            return fomatted.replace(/^\w/, c => c.toUpperCase());
        }
    } catch (e) {
        console.log(e);
    }

    return exid5 || "-";
}


export const formatCustName = (custName: string | undefined) => {
    if (!custName) return "-";

    try {
        let formatted = custName.replace(/^(Lastbilsstation|avräkning|ups|bring|tnt)([a-ö]+)/ig, "$1 $2").replace(/([a-ö]+)(sweden|sverige|express|avräkning|cargo|nordic)(ab)?$/ig, "$1 $2 $3").toLowerCase();

        formatted = formatted.replace(/(^|[^a-ö])([a-ö])/ig, (_, preceding, char) => preceding + char.toUpperCase());

        formatted = formatted.replace(/\s*(ab)$/i, " AB").trim();

        return formatted;
    } catch (e) {
        console.log(e);
    }

    return custName;
};