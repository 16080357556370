import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import TimeLimitTable from "./TimeLimitTable";




interface ITimeLimitProps {
    items: any[] | undefined;
    selectedItem: string | undefined;
    onSingleTap: (item: any) => void;
}


const TimeLimit = (props: ITimeLimitProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    return (
        <div className="tableBlock" style={{ border: "solid 1px black", alignSelf: "start" }}>
            <div className="column">
                <div>
                    <div className="horizontalRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Tidspassning</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <TimeLimitTable items={props.items} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap}></TimeLimitTable>
                </div>
            </div>
        </div>
    )
}


export default TimeLimit;