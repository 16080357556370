import React from "react";
import { columnLableMap, podColorMap, podLableMap, pods } from "../../../helpers/TranslationMaps";
import { formatASCII } from "../../../Services/format";
import ColumnSorting, { IColumnSearch, IColumnSorting } from "../../columnSorting";
import LoadingIndicator from "../../loadingIndicator";



export interface ITimeLimitTableProps {
    items: any[] | undefined;
    selectedItem: string | undefined;
    onSingleTap: (item: any) => void;
}
  
  const TimeLimitTable = (props: ITimeLimitTableProps) => {

    const [search, setSearch] = React.useState<IColumnSearch | undefined>(undefined);
    const [sorting, setSorting] = React.useState<IColumnSorting | undefined>(undefined);

    const columns: string[] = [
      "orderNr",
      "created",
      "custName",
      "recCompany",
      "recPostOrt",
      "recPostnr",
      "arrivalDate",
      "vehicle",
      "ordervikt",
      "ordervolume",
      "orderflakmeter",
      "orderpallplatser",
      "recLastLevTime",
      "pod",
      "missingCount",
    ];

    return (
      <table className="general-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th onClick={() => setSorting((prev) => ({ column: column, descending: prev?.column == column ? !prev?.descending: false }))}>{columnLableMap[column] + (sorting !== undefined && sorting?.column == column ? (sorting.descending ? " ↓": " ↑"): "")}</th>
          ))}
        </tr>
        <tr>
          {columns.map((column) => (
            <ColumnSorting column={column} onSearchChange={(search) => setSearch(search)} onSortChange={(sorting) => setSorting(sorting)}  />
          ))}
        </tr>
      </thead>
        <tbody>
          {props.items !== undefined ? props.items.filter((item: any) => search === undefined || (item[search.column] || "").toString().includes(search.search)).sort((a: any, b: any) => sorting === undefined ? 0 : ((a[sorting.column] || "").toString().localeCompare((b[sorting.column] || "").toString()) * (sorting.descending ? -1: 1))).map((item, index) => {
            const denied = item.pod == pods.denied;
            
            return (
              <tr key={index} className={item.orderNr == props.selectedItem ? "selectedRow": ""} onClick={() => props.onSingleTap(item)}>
                <td>{item.orderNr}</td>
                <td>{item.created?.split(" ")[0]?.replace(/\-00$/, "")}</td>
    
                <td>{item.custName}</td>
    
                <td>{formatASCII(item.recCompany)}</td>
                <td>{formatASCII(item.recPostOrt)}</td>
                <td>{item.recPostnr}</td>

                <td>{item.arrivalDate}</td>
                <td>{item.vehicle}</td>

                <td>{parseFloat(item.ordervikt).toFixed(1)}</td>
                <td>{parseFloat(item.ordervolume).toFixed(1)}</td>
                <td>{parseFloat(item.orderflakmeter).toFixed(1)}</td>
                <td>{parseFloat(item.orderpallplatser).toFixed(1)}</td>
    
                <td>{item.recLastLevTime?.split("T")[1]?.replace(/\-00$/, "")}</td>
                <td style={{ backgroundColor: podColorMap[item.pod], color: denied ? "#ffffff": undefined }}>{podLableMap[item.pod]}</td>
                <td>{item.missingCount}</td>
              </tr>
            )
          }): <LoadingIndicator />}
        </tbody>
      </table>
    );
  };
  
  export default React.memo(TimeLimitTable);