import React from "react";
import LoadingIndicator from "./loadingIndicator";


export interface IConvoView {
    conversations: any[] | undefined;
    selectedItem: string | undefined;
    onSingleTap: (item: any) => void;
    onDOubleClick: (item: any) => void;
}


const MessageView = (props: IConvoView) => {




    return (
        <div style={{ gap: "4px", padding: "4px" }}>
            {props.conversations !== undefined ? props.conversations.map((item, index) => (
                <div>{item}</div>
            )): <LoadingIndicator />}
        </div>
    );
}


export default React.memo(MessageView);