import React from "react";
import { columnLableMap, customerTypeMap, timeColorMap, timeWindow } from "../../../helpers/TranslationMaps";
import { formatASCII } from "../../../Services/format";
import ColumnSorting, { IColumnSearch, IColumnSorting } from "../../columnSorting";
import LoadingIndicator from "../../loadingIndicator";



export interface IPickupTableProps {
    items: any[] | undefined;
    onRightClick: (item: any) => void;
}
  
const AllStatusTable = (props: IPickupTableProps) => {

    const [search, setSearch] = React.useState<IColumnSearch | undefined>(undefined);
    const [sorting, setSorting] = React.useState<IColumnSorting | undefined>(undefined);

    const columns: string[] = [
      "pod",
      "orderNr",
      "kolliantal",
      "handOver",
      "created",
      "arrivalDate",
      "custNr",
      "avsCompany",
      "avsPostOrt",
      "avsPostnr",
      "recCompany",
      "recPostOrt",
      "recPostnr",
      "recFromTimeDeliver",
      "recLastLevTime",
      "vehicle",
      "kolliantal",
      "ordervikt",
      "ordervolume",
      "orderflakmeter",
      "orderpallplatser",
      "message",
      "Problem",
    ];

    return (
      <table className="general-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th onClick={() => setSorting((prev) => ({ column: column, descending: prev?.column == column ? !prev?.descending: false }))}>{columnLableMap[column] + (sorting !== undefined && sorting?.column == column ? (sorting.descending ? " ↓": " ↑"): "")}</th>
          ))}
        </tr>
        <tr>
          {columns.map((column) => (
            <ColumnSorting column={column} onSearchChange={(search) => setSearch(search)} onSortChange={(sorting) => setSorting(sorting)} />
          ))}
        </tr>
      </thead>
        <tbody>
          {props.items !== undefined ? props.items.filter((item: any) => search === undefined || (item[search.column] || "").toString().includes(search.search)).sort((a: any, b: any) => sorting === undefined ? 0 : ((a[sorting.column] || "").toString().localeCompare((b[sorting.column] || "").toString()) * (sorting.descending ? -1: 1))).map((item, index) => {
          const diff = Date.now() - Date.parse(item.created);

          const custNrColor = customerTypeMap[item.custNr];

          return (
            <tr key={index} onClick={() => props.onRightClick(item)}>
              <td>{item.status}</td>
              <td>{item.orderNr}</td>
              <td>{item.kollis}</td>
              <td>{item.handOver}</td>

              <td style={{ backgroundColor: timeColorMap[ diff > timeWindow ? "passed": "" ] }}>{item.created?.split(" ")[0]?.replace(/\-00$/, "")}</td>
              <td>{item.arrivalDate}</td>
  
              <td style={{ backgroundColor: custNrColor, color: custNrColor && "#ffffff" }}>{item.custNr}</td>
  
              <td>{formatASCII(item.avsCompany)}</td>
              <td>{formatASCII(item.avsPostOrt)}</td>
              <td>{item.avsPostNr}</td>

              <td>{formatASCII(item.recCompany)}</td>
              <td>{formatASCII(item.recPostOrt)}</td>
              <td>{item.recPostnr}</td>
  
              <td>{item.recFromTimeDeliver?.replace("T", " ").replace(/\-00$/, "")}</td>
              <td>{item.recLastLevTime?.replace("T", " ").replace(/\-00$/, "")}</td>
  
              <td>{item.vehicle}</td>
              <td>{item.kolliantal}</td>
              
              <td>{parseFloat(item.ordervikt).toFixed(1)}</td>
              <td>{parseFloat(item.ordervolume).toFixed(1)}</td>
              <td>{parseFloat(item.orderflakmeter).toFixed(1)}</td>
              <td>{parseFloat(item.orderpallplatser).toFixed(1)}</td>
  
              <td></td>
              <td></td>
            </tr>
          )}): <LoadingIndicator />}
        </tbody>
      </table>
    );
};
  
export default React.memo(AllStatusTable);