import { Dispatch } from "react";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { activeDataState } from "../reducers/activeDataReducer";
import { AuthState } from "../reducers/authReducer";
import { MessageState } from "../reducers/messageReducer";


export const addAuth = (auth: AuthState) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.AUTH_ADD,
      payload: auth,
    });
  };
};


export const clearAuth = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.AUTH_CLEAR,
    });
  };
};

export const addMessages = (change: MessageState) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.MESSAGE_ADD,
      payload: change,
    });
  };
};


export const clearMessages = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.MESSAGE_CLEAR,
    });
  };
};


export const addActiveData = (change: activeDataState) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DATA_ADD,
      payload: change,
    });
  };
};


export const clearActiveData = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DATA_CLEAR,
    });
  };
};