// ProtectedRoute.tsx
import { Navigate } from 'react-router-dom';
import StorageKeys from './helpers/StorageKeys';
import { AuthResult } from './models/AuthResult';
import { tokenLifetime } from './Services/authService';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const auth = JSON.parse(sessionStorage.getItem(StorageKeys.user) || "{}") as AuthResult | undefined;

    const tokenTime = tokenLifetime(auth?.refreshToken);

  if (!auth || tokenTime <= 60) {
    
    return <Navigate to="/Login" replace />;
  }

  return children;
};

export default ProtectedRoute;
