import { ComputedResult } from "../models/ComputedResult";
import { CurrentOrderData } from "../models/CurrentOrderData";
import { CarsResult, DepartmentsResult } from "../models/Department";
import { LocationData } from "../models/LocationData";
import { UserResult } from "../models/UserResult";
import { ConversationResult } from "../models/messages";
import { opterApi } from "./api";


export const getDepartments = async () => {
  const res = await opterApi.get<DepartmentsResult>("departments");
  return res.data;
};

export const getDepartmentCars = async () => {
  const res = await opterApi.get<CarsResult>("vehicles");
  return res.data;
};

export const getActiveUsers = async () => {
  const res = await opterApi.get<LocationData[]>("activeUsers");
  return res.data;
};

export const getActiveUser = async (id: number) => {
  const res = await opterApi.post<LocationData>("activeUser", { id });
  return res.data;
};

export const getOrderData = async () => {
  const res = await opterApi.get<CurrentOrderData>("allOrders");
  return res.data;
};

export const getUnDistributedOrders = async () => {
  const res = await opterApi.get<any[]>("unDistributedOrders");
  return res.data;
};

export const getDistributedOrders = async () => {
  const res = await opterApi.get<any[]>("distributedOrders");
  return res.data;
};

export const getLoadedOrders = async () => {
  const res = await opterApi.get<any[]>("loadedOrders");
  return res.data;
};

export const getDeliveredOrders = async () => {
  const res = await opterApi.get<any[]>("deliveredOrders");
  return res.data;
};

export const getComputedExtras = async (kolliid: string) => {
  const res = await opterApi.post<ComputedResult>("getOfflineComputedExtras", { kolliid });
  return res.data;
};

export const getConversations = async () => {
  const res = await opterApi.get<ConversationResult>("conversations");
  return res.data;
};

export const getUsers = async () => {
  const res = await opterApi.get<UserResult>("users");
  return res.data;
};