import { DocumentSearch24Regular, Print24Regular } from "@fluentui/react-icons";
import { ViewSettings } from "../../models/ViewSettings";
import IconToggleButton from "../IconToggleButton";


interface IPrintTableProps { 
    settings: ViewSettings;
    updateSettings: React.Dispatch<React.SetStateAction<ViewSettings>>;
}

const Print = (props: IPrintTableProps) => {
    return (
        <div className='topmenue'>
            <div className='largeSection'>
                <div className='horizontalRow m-1'>
                    <IconToggleButton 
                        Icon={DocumentSearch24Regular} 
                        text={"Fraktsedlar"}
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={DocumentSearch24Regular} 
                        text={"Kollietiketter"}
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={DocumentSearch24Regular} 
                        text={"Uppdragslista"}
                        onClick={() => {}} 
                        isActive={false} 
                    />

                    <IconToggleButton 
                        Icon={Print24Regular} 
                        text={"Fraktsedel"}
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Print24Regular} 
                        text={"Kollietiketter"} 
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Print24Regular} 
                        text={"Uppdragslista"} 
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <div style={{ fontSize: 15.5 }}>{"Välj adresser vid utskrift"}</div>
                </div>
                <div className='sectionFooter'>
                    Valda uppdrag
                </div>
            </div>
            
            <div className='smallSection'>
                <div className='horizontalRow m-1'>
                    <IconToggleButton 
                        Icon={DocumentSearch24Regular} 
                        text={"Uppdragslista"} 
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Print24Regular} 
                        text={"Uppdragslista"} 
                        onClick={() => {}} 
                        isActive={false} 
                    />
                </div>
                <div className='sectionFooter'>
                    Vald resurs
                </div>
            </div>

            <div className='smallSection'>
                <div className='horizontalRow m-1'>
                    <IconToggleButton 
                        Icon={DocumentSearch24Regular} 
                        text={"Uppdragslista"} 
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Print24Regular} 
                        text={"Uppdragslista"} 
                        onClick={() => {}} 
                        isActive={false} 
                    />
                </div>
                <div className='sectionFooter'>
                    Aktiva resurser
                </div>
            </div>

            <div className='largeSection'>
                <div>
                    <div className='horizontalRow m-1'>
                        <div style={{ fontSize: 15.5 }}>{"Välj skrivare vid utskrift"}</div>
                        <div style={{ fontSize: 15.5 }}>{"Välj standardskrivare"}</div>
                    </div>
                </div>
                <div className='sectionFooter'>
                    Inställningar
                </div>
            </div>
        </div>
    );
};

export default Print;
