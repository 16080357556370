import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import CreatedDirectPickupTable from "../pickups/createdDirectPickupTable";
import CreatePickupTable from "../pickups/createdPickuptable";


export interface IComingPickupsDirectProps {
    normal: any[] | undefined;
    direct: any[] | undefined;
    selectedItem: string | undefined;
    onSingleTap: (item: any) => void;
    onDoubleTap: (item: any) => void;
}

const ComingPickups = (props: IComingPickupsDirectProps) => {

    const createdNormalStats = calculateCostSummary(props.normal || []);
    const createdDirectStats = calculateCostSummary(props.direct || []);

    return (
        <div className="tableBlock" style={{ border: "solid 1px black" }}>
            <div className="column">
                <div>
                    <div className="horizontalRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Skapade hämtningar</div>
                        <CostDisplay summary={createdNormalStats} />
                    </div>
                    <CreatePickupTable items={props.normal} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap}></CreatePickupTable>
                </div>
                <div>
                    <div className="horizontalRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Skapade Direktkröningar</div>
                        <CostDisplay summary={createdDirectStats} />
                    </div>
                    <CreatedDirectPickupTable items={props.direct} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap}></CreatedDirectPickupTable>
                </div>
            </div>
        </div>
    )
}


export default ComingPickups;