
import {
    Settings24Regular
} from "@fluentui/react-icons";
import { ViewSettings } from "../../models/ViewSettings";
import IconToggleButton from "../IconToggleButton";

interface IRestProps {
    settings: ViewSettings;
    updateSettings: React.Dispatch<React.SetStateAction<ViewSettings>>;
}

const Rest = (props: IRestProps) => {
    return (
        <div className='topmenue'>
            <div className='largeSection'>
                <div className='horizontalRow m-1'>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Skicka meddelande till aktiva resurser"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Ruttoptimera valdra uppdrag (F9)"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Avisering för valda uppdrag (F10)"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Visa översikt över fordon"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Förslag"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Föregående datum (ALT + LEFT)"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Nästa datum (ALT + RIGHT)"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Tilldela resurs till valda uppdrag (CTRL + M)"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Expandera rader (CTRL + E)"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Kollapsa rader (CTRL + K)"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Visa bara ledia resurser i kön"
                            onClick={() => props.updateSettings({...props.settings, showOnlyEmptyUsers: !props.settings.showOnlyEmptyUsers})} 
                            isActive={props.settings.showOnlyEmptyUsers} 
                        />
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Send debug information"
                            onClick={() => {}} 
                            isActive={false} 
                        />
                    </div>

                    <div className="column">
                        <IconToggleButton 
                            Icon={Settings24Regular} 
                            text="Visa samlastningar för resurser"
                            onClick={() => props.updateSettings({...props.settings, showCollectiveLoading: !props.settings.showCollectiveLoading})} 
                            isActive={props.settings.showCollectiveLoading} 
                        />
                    </div>

                </div>
                <div className='sectionFooter'>
                    Övrigt
                </div>
            </div>

            <div className='smallSection'>

                <div className="column">
                    <IconToggleButton 
                        Icon={Settings24Regular} 
                        text="Automatfördela"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Settings24Regular} 
                        text="Ett uppdrag"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                </div>

                <div className='sectionFooter'>
                    Valda uppdrag
                </div>
            </div>
            <div className='smallSection'>

                <div className="column">
                    <IconToggleButton 
                        Icon={Settings24Regular} 
                        text="Skelelfteå lastbils"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Settings24Regular} 
                        text="Ingen"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                    <IconToggleButton 
                        Icon={Settings24Regular} 
                        text="Logga på"
                        onClick={() => {}} 
                        isActive={false} 
                    />
                </div>

                <div className='sectionFooter'>
                    Logga på
                </div>
            </div>
        </div>
    );
};

export default Rest;