

import { CaretDown24Regular, CaretUp24Regular, Dismiss24Filled } from '@fluentui/react-icons';
import { useState } from 'react';


interface WindowProps {
    title: string;
    disableClose?: boolean;
    children?: JSX.Element;
    onClose?: () => void;
}


const WindowCard = (props: WindowProps) => {
    
    const [visible, setVisible] = useState<boolean>(true);
    // const [removed, setRemoved] = useState<boolean>(false);

    // if (removed) {
    //     return
    // }

    return (
        <div className='window'>
            <div>
                <div className='windowHeader'>
                    <div>{props.title}</div>
                    <div style={{ flex: 1 }}></div>
                    {visible ? <CaretDown24Regular onClick={() => setVisible(false)}/>: <CaretUp24Regular onClick={() => setVisible(true)}/>}
                    <div style={{ marginLeft: 3 }}></div>
                    {!props.disableClose && <Dismiss24Filled />}
                </div>
                {visible && <div className='windowContent'>{props.children}</div>}
            </div>
        </div>
    );
}

export default WindowCard;