




const LoadingIndicator = () => {

    return (
        <center style={{ padding: 50, width: "100%", justifyContent: "center", backgroundColor: "green" }}>Loading...</center>
    );
}


export default LoadingIndicator;