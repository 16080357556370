import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import DeliveredTable from "./deliveredTable";



interface IDeliveredProps {
    items: any[] | undefined;
    selectedItem: string | undefined;
    onSingleTap: (item: any) => void;
}


const Delivered = (props: IDeliveredProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    return (
        <div className="tableBlock" style={{ border: "solid 1px black", alignSelf: "start" }}>
            <div className="column">
                <div>
                    <div className="horizontalRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Alla</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <DeliveredTable items={props.items} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap}></DeliveredTable>
                </div>
            </div>
        </div>
    )
}


export default Delivered;