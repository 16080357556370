// App.tsx

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import General from '../components/TopTab/General';
import MapTab from '../components/TopTab/Map';
import Print from '../components/TopTab/Print';
import Rest from '../components/TopTab/Rest';
import { Department, Terminal, Trailer, Vehicle } from '../models/Department';
import { LocationData } from '../models/LocationData';
import { getActiveUsers, getDeliveredOrders, getDepartmentCars, getDepartments, getDistributedOrders, getLoadedOrders, getUnDistributedOrders } from '../Services/lookupService';
import { actionCreators } from '../state';
import { activeDataState } from '../state/reducers/activeDataReducer';
import { RootState, store } from '../state/store';
  
import MessageView from '../components/MessageView';
import MissionInfo from '../components/MissionInfo';
import StatusMenue from '../components/StatusMenue';
import ComingDeliveries from '../components/tableTabs/ComingDeliveries/comingDeliveries';
import ComingPickups from '../components/tableTabs/ComingPickups/comingPickups-direct';
import Delivered from '../components/tableTabs/Delivered/delivered';
import Deliveries from '../components/tableTabs/Deliveries/deliveries';
import PickupsTables from '../components/tableTabs/pickups/pickups-direct';
import AllStatus from '../components/tableTabs/Status/AllStatus';
import TimeLimit from '../components/tableTabs/TimeLimit/TimeLimit';
import DisplayTab from '../components/TopTab/Display';
import WindowCard from '../components/Window';
import StorageKeys from '../helpers/StorageKeys';
import { AuthResult } from '../models/AuthResult';
import { CurrentOrderData } from '../models/CurrentOrderData';
import { defaultViewSettings, ViewSettings } from '../models/ViewSettings';
import { dispatchOrder } from '../Services/operationService';
import { initSocketStream } from '../Services/Socket';
import '../styles/styles.css';

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});


interface HidableButton {
    name: string;
    hidden: boolean;
}

const GeneralPage = () => {

    const topMenuItems: string[] = ["Allmänt", "Utskrift", "Karta", "Övrigt", "Visa"];
    const resourceMenuItems: string[] = ["Uppdrag", "Resursinformation"];
    const midMenuItems: string[] = ["Fordon", "Trailrar", "Terminal"];

    const sideMenuItems: HidableButton[] = [ 
        {
            name:   "underLeverantörer",
            hidden: false
        }, 
        {
            name:   "Filter",
            hidden: false
        }, 
        {
            name:   "ändringar",
            hidden: false
        }, 
        {
            name:   "Meddelanden",
            hidden: false
        }, 
        {
            name:   "Rutter",
            hidden: false
        }, 
        {
            name:   "Kö",
            hidden: false
        }, 
        {
            name:   "k2 Link",
            hidden: false
        }, 
        {
            name:   "Karta",
            hidden: false
        }, 
        {
            name:   "Resursförslag",
            hidden: false
        }, 
    ];

    const tableTopMenuItems: string[] = ["Uppdrag"/*, "Schemaplanering", "Resa"*/];
    const tableBottomMenuItems: string[] = ["Hämtningar / direktkörningar", "Utkörningar", "Kommande Hämtningar / direktkörningar", "Kommande Utkörningar", "Lossade", "Alla Statusar", "Tidspassning"];

    // const messageData = useSelector((state: RootState) => state.messageData);
    const activeData = useSelector((state: RootState) => state.activeData);
    const auth = useSelector((state: RootState) => state.auth);

    const addActiveData = bindActionCreators(actionCreators, store.dispatch).addActiveData;
    const addAuth = bindActionCreators(actionCreators, store.dispatch).addAuth;

    const [departments, setDepartments] = useState<Department[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [trailers, setTrailers] = useState<Trailer[]>([]);
    const [terminals, setTerminals] = useState<Terminal[]>([]);

    const [userPositions, setUserPositions] = useState<LocationData[]>([]);
    const [conversations, setConversations] = useState<any[] | undefined>([]);
    const [activeOrders, setActiveOrders] = useState<CurrentOrderData | undefined>(undefined);

    const [userSettings, setUserSettings] = useState<ViewSettings>(defaultViewSettings);

    const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>(undefined);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(undefined);
    const [selectedTrailer, setSelectedTrailer] = useState<Trailer | undefined>(undefined);
    const [selectedTerminal, setSelectedTerminal] = useState<Terminal | undefined>(undefined);

    const [selectedMission, setSelectedMission] = useState<any | undefined>(undefined);

    const [activeKeys, setActiveKeys] = useState<Set<string>>(new Set<string>());

    const [selectedTopTab, setSelectedTopTab] = useState<number>(0);
    const [selectedResourceTab, setSelectedResourceTab] = useState<number>(0);
    const [selectedMidTab, setSelectedMidTab] = useState<number>(0);
    const [selectedTableTopTab, setSelectedTableTopTab] = useState<number>(0);
    const [selectedTableBottomTab, setSelectedTableBottomTab] = useState<number>(0);



    const _getDepartments = async () => {
        try {
            const result = await getDepartments();

            console.log("departments", result.departments.length);

            if (result) {
                const dep = result.departments.find((dep) => dep.departmentCode === auth?.auth.login.department);

                setDepartments(result.departments);
                setSelectedDepartment(dep);
                setTerminals(result.departments.map<Terminal>((dep) => ({ name: dep.departmentName, code: dep.departmentCode })).concat(result.external));
            }
        } catch (error) {
            console.log(error);
        }
    }

    
    const _getVehicles = async () => {
        try {
            const result = await getDepartmentCars();

            console.log("vehicles", result.vehicles.length);
            
            if (result) {
                setVehicles(result.vehicles);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const _getLocations = async () => {
        try {
            const result = await getActiveUsers();

            console.log("users", result.length);
            
            if (result) {
                setUserPositions(result);

                addActiveData({ usePositions: result });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const _getunDistributed = async () => {
        const start = DateTime.now();
        try {
          const result = await getUnDistributedOrders();
    
          console.log("unDistributed orders", result.length);
          
          if (result) {
            setActiveOrders((prev) => ({ ...prev, unDistributed: result }));
          }
        } catch (error) {
          console.log(error);
        }
    
        console.log("unDistributed get time", start.diffNow().milliseconds * -1, "ms");
      };
    
    const _getDistributed = async () => {
        const start = DateTime.now();
        try {
          const result = await getDistributedOrders();
    
          console.log("Distributed orders", result.length);
          
          if (result) {
            setActiveOrders((prev) => ({ ...prev, distributed: result }));
          }
        } catch (error) {
          console.log(error);
        }
    
        console.log("Distributed get time", start.diffNow().milliseconds * -1, "ms");
      };
    
    const _getLoaded = async () => {
        const start = DateTime.now();
        try {
          const result = await getLoadedOrders();
    
          console.log("Loaded orders", result.length);
          
          if (result) {
            setActiveOrders((prev) => ({ ...prev, loaded: result }));
          }
        } catch (error) {
          console.log(error);
        }
    
        console.log("Loaded get time", start.diffNow().milliseconds * -1, "ms");
      };
    
    const _getDelivered = async () => {
        const start = DateTime.now();
        try {
          const result = await getDeliveredOrders();
    
          console.log("Delivered orders", result.length);
          
          if (result) {
            setActiveOrders((prev) => ({
              ...prev,
              department: selectedDepartment?.departmentCode,
              delivered: result,
            }));
          }
        } catch (error) {
          console.log(error);
        }
    
        console.log("Delivered get time", start.diffNow().milliseconds * -1, "ms");
    };
    
    useEffect(() => {
        if (departments.length === 0) {
            _getDepartments();
        }
        if (vehicles.length === 0) {
            _getVehicles();
        }
        if (userPositions.length === 0) {
            _getLocations();
        }
        if (activeOrders === undefined) {
            _getunDistributed();
            _getDistributed();
            _getLoaded();
            _getDelivered();
        }
    }, []);

    // useEffect(() => {
    //     console.log("activeOrders", {
    //         "created": activeOrders?.unDistributed?.length,
    //         "distributed": activeOrders?.distributed?.length,
    //         "loaded": activeOrders?.loaded?.length,
    //         "delivered": activeOrders?.delivered?.length
    //     });
    // }, [activeOrders]);

    useEffect(() => {
        setUserPositions(activeData.usePositions);
    }, [activeData]);

    useEffect(() => {
        if (activeOrders !== undefined) {
            const newAuth: AuthResult = { ...auth!.auth, login: { ...auth!.auth.login, department: selectedDepartment?.departmentCode } };

            addAuth({ auth: newAuth });

            setActiveOrders(undefined);

            setTimeout(() => {
                _getunDistributed();
                _getDistributed();
                _getLoaded();
                _getDelivered();
            }, 250);

            sessionStorage.setItem(StorageKeys.user, JSON.stringify(newAuth));
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (auth?.auth.refreshToken && selectedDepartment) {
          initSocketStream(auth?.auth.accessToken, selectedDepartment, vehicles, setActiveOrders);
        }
    }, [auth?.auth.refreshToken, selectedDepartment, vehicles, setActiveOrders]);

    useEffect(() => {
        if (selectedMission !== undefined) {
            setSelectedMission(undefined);
        }
    }, [selectedTableBottomTab]);


    useEffect(() => {
        const handleKeyDown = (ev: KeyboardEvent) => {
          // if (ev.key.length > 1 && ev.key.startsWith('F')) {
          //   ev.preventDefault();
          // }
    
          setActiveKeys((prevKeys) => {
            if (prevKeys.has(ev.key)) {
              return prevKeys;
            }
    
            const newKeys = new Set(prevKeys);
            newKeys.add(ev.key);
    
            console.log(`Key pressed: ${ev.key}`, Array.from(newKeys));
    
            // if (newKeys.has('Control') && newKeys.has('s')) {
            //   ev.preventDefault(); // Prevent the browser's save dialog
            //   console.log('Ctrl+S shortcut triggered!');
            // }
    
            return newKeys;
          });
        };
    
        const handleKeyUp = (ev: KeyboardEvent) => {
          setActiveKeys((prevKeys) => {
            if (!prevKeys.has(ev.key)) {
              return prevKeys;
            }
    
            const newKeys = new Set(prevKeys);
            newKeys.delete(ev.key);
    
            console.log(`Key released: ${ev.key}`, Array.from(newKeys));
    
            return newKeys;
          });
        };
    
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
    
        console.log('Key listeners added');
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
          window.removeEventListener('keyup', handleKeyUp);
          console.log('Key listeners removed');
        };
      }, []);


    const moveItem = async (item: any) => {
        if (selectedVehicle === undefined) return;

        try {
            console.log("Dispatching order", item.orderNr, "to", selectedVehicle.vehicleName);

            const result = await dispatchOrder(item.orderNr, selectedVehicle.vehicleName);

            console.log("dispatch resulr", result);
        } catch (e) {
            console.log("Error dispatching order", e);
        }
    }

    const selectItem = (item: any) => {
        console.log("Item Single tapped", item);

        if (selectedMission?.orderNr == item?.orderNr) {
            setSelectedMission(undefined);
        } else {
            setSelectedMission(item);
        }
    }

    const selectStatusItem = (item: any) => {
        console.log("STatus Item Single tapped", item);

        // if (selectedMission?.orderNr == item?.orderNr) {
        //     setSelectedMission(undefined);
        // } else {
        //     setSelectedMission(item);
        // }
    }

    const topTabs = topMenuItems.map((value, index) => (
        <div
            key={index}
            className={`Tab ${index === selectedTopTab ? 'selected' : 'notSelected'}`}
        >
            <div onClick={() => setSelectedTopTab(index)}>{value}</div>
        </div>
    ));

    const resourceTabs = resourceMenuItems.map((value, index) => (
        <div
            key={index}
            className={`invTab ${index === selectedResourceTab ? 'invSelected' : 'invNotSelected'}`}
        >
            <div onClick={() => setSelectedResourceTab(index)}>{value}</div>
        </div>
    ));

    const midTabs = midMenuItems.map((value, index) => (
        <div
            key={index}
            className={`invTab ${index === selectedMidTab ? 'invSelected' : 'invNotSelected'}`}
        >
            <div onClick={() => setSelectedMidTab(index)}>{value}</div>
        </div>
    ));

    const tableTopTabs = tableTopMenuItems.map((value, index) => (
        <div
            key={index}
            className={`Tab ${index === selectedTableTopTab ? 'selected' : 'notSelected'}`}
            style={{ padding: "2px 4px 2.5px 4px" }}
        >
            <div onClick={() => setSelectedTableTopTab(index)}>{value}</div>
        </div>
    ));


    const tableBottomTabs = tableBottomMenuItems.map((value, index) => (
        <div
            key={index}
            className={`Tab ${index === selectedTableBottomTab ? 'selected' : 'notSelected'}`}
            style={{ padding: "2px 4px 2.5px 4px", flexWrap: "nowrap" }}
        >
            <div onClick={() => setSelectedTableBottomTab(index)}>{value}</div>
        </div>
    ));

    const topTabsWithInfo = (
        <>
            {topTabs}
            <div className='infoBox'>Total active users {userPositions.length}</div>
        </>
    );

    const topMenue = () => {

        switch (selectedTopTab) {
            case 0:
                return <General settings={userSettings} updateSettings={setUserSettings} departments={departments} selectedDepartment={selectedDepartment} onDepartmentChange={(department) => setSelectedDepartment(department)}></General>
            case 1:
                return <Print settings={userSettings} updateSettings={setUserSettings}></Print>
            case 2:
                return <MapTab settings={userSettings} updateSettings={setUserSettings}></MapTab>
            case 3:
                return <Rest settings={userSettings} updateSettings={setUserSettings}></Rest>
            case 4:
                return <DisplayTab settings={userSettings} updateSettings={setUserSettings}></DisplayTab>
        }
    };


    const bottomTables = () => {
        switch (selectedTableBottomTab) {
            case 0:
                return <PickupsTables 
                    selectedItem={selectedMission?.orderNr} 
                    onSingleTap={selectItem} 
                    onDoubleTap={moveItem}
                    createdNormal={activeOrders?.unDistributed?.filter((order) => order !== undefined && order.delivery == "0")} 
                    createdDirect={[]} 
                    distributed={activeOrders?.distributed?.filter((order) => order !== undefined && order.delivery == "0" && (selectedVehicle === undefined || selectedVehicle.vehicleName === order.vehicle))} 
                    loaded={activeOrders?.loaded?.filter((order) => order !== undefined && order.delivery == "0" && (selectedVehicle === undefined || selectedVehicle.vehicleName === order.vehicle))} 
                />
            case 1:
                return <Deliveries 
                    selectedItem={selectedMission?.orderNr} 
                    onSingleTap={selectItem} 
                    onDoubleTap={moveItem} 
                    created={activeOrders?.unDistributed?.filter((order) => order !== undefined && order.delivery == "1")} 
                    distributed={activeOrders?.distributed?.filter((order) => order !== undefined && order.delivery == "1" && (selectedVehicle === undefined || selectedVehicle.vehicleName === order.vehicle))} 
                    loaded={activeOrders?.loaded?.filter((order) => order !== undefined && order.delivery == "1" && (selectedVehicle === undefined || selectedVehicle.vehicleName === order.vehicle))}
                />
            case 2:
                return <ComingPickups 
                    normal={[]} 
                    direct={[]} 
                    selectedItem={selectedMission?.orderNr}                     
                    onSingleTap={selectItem} 
                    onDoubleTap={moveItem} 
                />
            case 3:
                return <ComingDeliveries 
                    created={[]}
                    selectedItem={selectedMission?.orderNr}                     
                    onSingleTap={selectItem} 
                    onDoubleTap={moveItem} 
                />
            case 4:
                return <Delivered 
                    items={activeOrders?.delivered}
                    selectedItem={selectedMission?.orderNr} 
                    onSingleTap={selectItem}
                />
            case 5:
                return <AllStatus 
                    items={[]}
                    onRightClick={selectStatusItem}
                />
            case 6:
                return <TimeLimit 
                    items={activeOrders?.loaded?.filter((item: any) => (item.extraid5 as string | undefined)?.toLowerCase().includes("tidspass"))}
                    selectedItem={selectedMission?.orderNr}
                    onSingleTap={selectItem}
                />
        }
    };

    const resourceMenu = (
        <div>
            <WindowCard title="Resursinformation" disableClose>
                <MissionInfo mission={selectedMission}></MissionInfo>
            </WindowCard>
            <div style={{ marginLeft: 10 }}>
                {resourceTabs}
            </div>
        </div>
    );

    const vehicleMenue = (
        <div>
            <div style={{ fontSize: 18, margin: 3 }}>{midMenuItems[selectedMidTab] + (selectedMidTab == 0 ? ` ${userPositions.filter((user) => selectedDepartment === undefined || user.department == selectedDepartment?.departmentCode).length} aktiva`: "")}</div>
            <div key={"7tysugtfaf"} style={{ marginLeft: 5 }}>
                <div className='vehicleSection'>
                    {selectedMidTab === 0 && vehicles.filter((vehicle) => selectedDepartment === undefined || vehicle.department == selectedDepartment?.departmentCode).map((vehicle) => {

                        const user = userPositions.find((user) => user.vehicle == vehicle.vehicleName);

                        return (
                            <div id={vehicle.id} className={`vehicleItem ${(selectedVehicle?.id == vehicle.id ? "selectedMidItem": "")} ${user ? (moment().diff(moment(user.timestamp), 'minutes') > 30 ? "recentlyActive" : "active"): ""}`} onClick={() => {
                                if (selectedVehicle?.id != vehicle.id) {
                                    setSelectedVehicle(vehicle); 
                                } else {
                                    setSelectedVehicle(undefined);
                                }
                            }}>
                                {vehicle.vehicleName}
                            </div>
                        ); 
                    })}
                    {selectedMidTab === 1 && trailers.filter((trailer) => selectedDepartment === undefined || trailer.department == selectedDepartment?.departmentCode).map((trailer) => (
                        <div id={trailer.id} className={`vehicleItem ${(selectedTrailer?.id == trailer.id ? "selectedMidItem": "")}`} onClick={() => {
                            if (selectedTrailer?.id != trailer.id) {
                                setSelectedTrailer(trailer); 
                            } else {
                                setSelectedTrailer(undefined);
                            }
                        }}>
                            {trailer.trailerPlate}
                        </div>
                    ))}
                    {selectedMidTab === 2 && terminals.map((terminal) => (
                        <div id={"" + terminal.code} className={`vehicleItem active ${(selectedTerminal?.code == terminal.code ? "selectedMidItem": "")}`} onClick={() => {
                            if (selectedTerminal?.code != terminal.code) {
                                setSelectedTerminal(terminal); 
                            } else {
                                setSelectedTerminal(undefined);
                            }
                        }}>
                            {terminal.name}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ marginLeft: 10 }}>
                {midTabs}
            </div>
        </div>
    );

    const bottomMenueCluster = (
        <div style={{ justifyContent: "start", minWidth: 1000 }}>
            <div style={{ flex: 1, flexDirection: "row", marginLeft: 4 }}>
                {tableTopTabs}
            </div>
            <div style={{ flex: 1, flexDirection: "row" }}>
                {tableBottomTabs}
            </div>
            {bottomTables()}
        </div>
    );

    const BottomSection = (
        <div className='bottomSection'>
            <div className='bottomLeftSection'>
                {bottomMenueCluster}
            </div>
            <div className='bottomrightSection'>
                <MapComponent data={activeData} department={selectedDepartment} />
            </div>
        </div>
    );

    const ui = (
        <div className='mainContainer'>
            <div className='horizontalRow'>
                <div className='topHeader'>
                    <div>{topTabsWithInfo}</div>
                    <div>{topMenue()}</div>
                </div>
                <StatusMenue activeOrders={activeOrders} />
            </div>
            <div className='horizontalRow'>
                <div>
                    <div>{resourceMenu}</div>
                    <div>{vehicleMenue}</div>
                    <div>{BottomSection}</div>
                </div>
                <div>
                    <MessageView conversations={conversations} selectedItem={undefined} onSingleTap={(item: any) => {}} onDOubleClick={(item: any) => {}}></MessageView>
                </div>
                <div className='verticalSection'>
                    {sideMenuItems.filter((item: HidableButton) => !item.hidden).map((value) => (
                        <div className='verticalButton'>{value.name}</div>
                    ))}
                </div>
            </div>
        </div>
    );

    return ui;
};

function getMapIcon(rotation: number): L.DivIcon {
    return L.divIcon({
      html: `<img src="https://uxwing.com/wp-content/themes/uxwing/download/arrow-direction/map-direction-navigation-top-blue-icon.png" style="transform-origin: center; transform: rotate(${rotation}deg); width: 20px; height: 20px;">`,
      iconSize: [18, 18],
      className: '',
    });
}
  
const Markers = (props: MapProps) => {
    const filteredUsers = props.data.usePositions.filter(
      (user) =>
        (props.department?.departmentCode === undefined ||
          user.department === props.department?.departmentCode) &&
        moment().diff(moment(user.timestamp), 'minutes') < 20
    );
  
    return (
      <>
        {filteredUsers.map((user) => (
          <Marker
            key={user.uid}
            position={[user.latitude, user.longitude]}
            icon={getMapIcon(user.heading)}
          >
            <Popup>
              {`${user.username}, ${user.vehicle}, ${(user.speed * 3.6).toFixed(0)} km/h, ${user.city}, ${user.timestamp}`}
            </Popup>
          </Marker>
        ))}
      </>
    );
  };

interface MapProps {
    data: activeDataState;
    department: Department | undefined;
}

const MapComponent = (props: MapProps) => {
    return (
      <div style={{ height: '650px', width: '550px', border: '2px solid blue' }}>
        <MapContainer
          center={[63.278154, 16.971084]}
          zoom={6.75}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            maxZoom={19}
          />
          <Markers data={props.data} department={props.department} />
        </MapContainer>
      </div>
    );
};

export default GeneralPage;
