import { FluentIcon } from "@fluentui/react-icons";





const IconToggleButton = ({ Icon, text, column, onClick, isActive }: { Icon: FluentIcon, text: string, column?: boolean, onClick: () => void, isActive: boolean }) => {

    return (
        <div className={isActive ? 'actionButton': 'inactiveActionButton'} style={{ display: "flex", flexDirection: column ? "column": "row", alignItems: "center" }} onClick={onClick}>
            <Icon />
            <div style={{ marginLeft: 5, fontSize: 15 }}>{text}</div>
        </div>
    )
}


export default IconToggleButton;