import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import { default as CreatedDirectPickupTable, default as CreatePickupTable } from "./createdDirectPickupTable";
import PickupTable from "./pickuptable";


export interface IPickupsDirectProps {
    createdNormal: any[] | undefined;
    createdDirect: any[] | undefined;
    distributed: any[] | undefined;
    loaded: any[] | undefined;
    selectedItem: string | undefined;
    onSingleTap: (item: any) => void;
    onDoubleTap: (item: any) => void;
}

const PickupsTables = (props: IPickupsDirectProps) => {

    const createdNormalStats = calculateCostSummary(props.createdNormal || []);
    const createdDirectStats = calculateCostSummary(props.createdDirect || []);
    const distributedStats = calculateCostSummary(props.distributed || []);
    const loadedStats = calculateCostSummary(props.loaded || []);

    return (
        <div className="tableBlock" style={{ border: "solid 1px black" }}>
            <div className="horizontalRow" style={{ alignItems: 'flex-start' }}>
                <div className="column">
                    <div>
                        <div className="horizontalRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade hämtningar</div>
                            <CostDisplay summary={createdNormalStats} />
                        </div>
                        <CreatePickupTable items={props.createdNormal} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap}></CreatePickupTable>
                    </div>
                    <div>
                        <div className="horizontalRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade Direktkröningar</div>
                            <CostDisplay summary={createdDirectStats} />
                        </div>
                        <CreatedDirectPickupTable items={props.createdDirect} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap}></CreatedDirectPickupTable>
                    </div>
                </div>
                <div className="column">
                    <div>
                        <div className="horizontalRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Fördelade</div>
                            <CostDisplay summary={distributedStats} />
                        </div>
                        <PickupTable items={props.distributed} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap}></PickupTable>
                    </div>
                    <div>
                        <div className="horizontalRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Lastade</div>
                            <CostDisplay summary={loadedStats}/>
                        </div>
                        <PickupTable items={props.loaded} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap}></PickupTable>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PickupsTables;