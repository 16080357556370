


import React from 'react';
import { columnLableMap, customerTypeMap, podColorMap, timeColorMap, timeWindow } from '../../../helpers/TranslationMaps';
import { formatASCII } from '../../../Services/format';
import '../../../styles/Tables.css';
import ColumnSorting, { IColumnSearch, IColumnSorting } from '../../columnSorting';
import LoadingIndicator from '../../loadingIndicator';

export interface IPickupTableProps {
  items: any[] | undefined;
  selectedItem: string | undefined;
  onSingleTap: (item: any) => void;
}

const DeliveredTable = (props: IPickupTableProps) => {

  const [search, setSearch] = React.useState<IColumnSearch | undefined>(undefined);
  const [sorting, setSorting] = React.useState<IColumnSorting | undefined>(undefined);

  const columns: string[] = [
    "pod",
    "shippingNr",
    "orderNr",
    "created",
    "custNr",
    "route",
    "avsCompany",
    "avsPostOrt",
    "fromTimePickup",
    "toTimePickup",
    "recCompany",
    "recPostOrt",
    "recPostnr",
    "recFromTimeDeliver",
    "recLastLevTime",
    "wasDirect",
    "H",
    "L1",
    "L2",
    "L3",
    "vehicle",
    "service",
    "vehicleType",
    "ordervikt",
    "ordervolume",
    "orderflakmeter",
    "orderpallplatser",
    "cargoType",
    "pod",
    "message",
    "Problem",
    "addon",
    "sorting",
  ];

  return (
    <table className="general-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th onClick={() => setSorting((prev) => ({ column: column, descending: prev?.column == column ? !prev?.descending: false }))}>{columnLableMap[column] + (sorting !== undefined && sorting?.column == column ? (sorting.descending ? " ↓": " ↑"): "")}</th>
          ))}
        </tr>
        <tr>
          {columns.map((column) => (
            <ColumnSorting column={column} onSearchChange={(search) => setSearch(search)} onSortChange={(sorting) => setSorting(sorting)} />
          ))}
        </tr>
      </thead>
      {props.items !== undefined ? <tbody>
        {props.items.filter((item: any) => search === undefined || (item[search.column] || "").toString().includes(search.search)).sort((a: any, b: any) => sorting === undefined ? 0 : (a[sorting.column].localeCompare(b[sorting.column]) * (sorting.descending ? -1: 1))).map((item, index) => {
          const diff = Date.now() - Date.parse(item.created);

          const custNrColor = customerTypeMap[item.custNr];

          const weight = parseFloat(item.ordervikt);
          const volume = parseFloat(item.ordervolume);
          const flakmeter = parseFloat(item.orderflakmeter);
          const pallplatser = parseFloat(item.orderpallplatser);

          return (
            <tr key={index} className={item.orderNr == props.selectedItem ? "selectedRow": ""} onClick={() => props.onSingleTap(item)}>
              <td style={{ backgroundColor: podColorMap[3] }}>Lossad</td>
              <td>{item.shippingNr || "?"}</td>
              <td>{item.orderNr}</td>
              <td style={{ backgroundColor: timeColorMap[ diff > timeWindow ? "passed": "" ] }}>{item.created?.split(" ")[0]?.replace(/\-00$/, "")}</td>

              <td style={{ backgroundColor: custNrColor, color: custNrColor && "#ffffff" }}>{item.custNr}</td>
              <td>?</td>

              <td>{formatASCII(item.avsCompany)}</td>
              <td>{formatASCII(item.avsPostOrt)}</td>

              <td>{item.fromTimePickup?.split("T")[1]?.replace(/\-00$/, "")}</td>
              <td>{item.toTimePickup?.split("T")[1]?.replace(/\-00$/, "")}</td>

              <td>{formatASCII(item.recCompany)}</td>
              <td>{formatASCII(item.recPostOrt)}</td>
              <td>{item.recPostnr}</td>

              <td>{item.recFromTimeDeliver?.split("T")[1]?.replace(/\-00$/, "")}</td>
              <td>{item.recLastLevTime?.split("T")[1]?.replace(/\-00$/, "")}</td>

              <td>{item.wasDirect}</td>
              <td>H</td>

              <td></td>
              <td></td>
              <td></td>

              <td style={{ backgroundColor: item.vehicle ? podColorMap[3]: undefined }}>{item.vehicle}</td>

              <td></td>
              <td></td>
              
              <td>{weight > 0 ? weight.toFixed(1): ""}</td>
              <td>{volume > 0 ? volume.toFixed(1): ""}</td>
              <td>{flakmeter > 0 ? flakmeter.toFixed(1): ""}</td>
              <td>{pallplatser > 0 ? pallplatser.toFixed(1): ""}</td>

              <td>gods</td>
              <td style={{ backgroundColor: podColorMap[3] }}>Lossad</td>

              <td>?</td>
              <td>?</td>
              <td>?</td>
              <td>?</td>
            </tr>
          )
        })}
      </tbody>: <LoadingIndicator />}
    </table>
  );
};

export default React.memo(DeliveredTable);